<template>
  <div class="rule">
    <div class="imgs flex">
      <div class="img-box">
        <el-image :src="chuneng1" fit="contain"></el-image>
      </div>
      <div class="img-box" style="height: 50rem">
        <el-image :src="chuneng2" fit="contain"></el-image>
      </div>
    </div>
    <div class="data">
      <div class="item flex">
        <div class="label">概要</div>
        <div class="val flex">
          <div class="left">
            <ul>
              <li class="flex">
                <span>尺寸</span>
                <span>1359*1086*793mm（80kWh）</span>
                <span>2187*1086*793mm（160kWh）</span>
              </li>
              <li class="flex">
                <span>LED显示屏尺寸（英寸）</span>
                <span>18.5</span>
              </li>
              <li class="flex">
                <span>LED触摸屏尺寸（英寸）</span>
                <span>7</span>
              </li>
            </ul>
          </div>
          <div class="right">
            <ul>
              <li class="flex">
                <span>重量（Kg）</span>
                <span>～2000（80kWh）</span>
                <span>～2700（160kWh）</span>
              </li>
              <li class="flex">
                <span>颜色</span>
                <span>太空银/深空灰（耐候涂层）</span>
              </li>
              <li class="flex">
                <span>防护等级</span>
                <span>IP65</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="item flex">
        <div class="label">储能参数</div>
        <div class="val flex">
          <div class="left">
            <ul>
              <li class="flex">
                <span>电池容量（kWh）</span>
                <span>80/160</span>
              </li>
              <li class="flex">
                <span>冷却方式</span>
                <span>智能风冷</span>
              </li>
              <li class="flex">
                <span>最大充电功率（kW）</span>
                <span>30/60</span>
              </li>
            </ul>
          </div>
          <div class="right">
            <ul>
              <li class="flex">
                <span>电池充电倍率</span>
                <span>≤1C</span>
              </li>
              <li class="flex">
                <span>电池放电倍率</span>
                <span>≤2C </span>
              </li>
              <li class="flex">
                <span>电池效率</span>
                <span>≥ 94.5 % </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="item flex">
        <div class="label">充电参数</div>
        <div class="val flex">
          <div class="left">
            <ul>
              <li class="flex">
                <span>充电线缆</span>
                <span>5m，250A，GB</span>
              </li>
              <li class="flex">
                <span>输出电压范围（Vdc）</span>
                <span>50-1000</span>
              </li>
              <li class="flex">
                <span>输出电流范围（A）</span>
                <span>0-456</span>
              </li>
              <li class="flex">
                <span>启动方式</span>
                <span>扫码启动/刷卡启动</span>
              </li>
            </ul>
          </div>
          <div class="right">
            <ul>
              <li class="flex">
                <span>充电枪数</span>
                <span>2</span>
              </li>
              <li class="flex">
                <span>充电效率</span>
                <span>≥97.8% @最高工作效率</span>
              </li>
              <li class="flex">
                <span>充电功率（kW）</span>
                <span>190/220</span>
              </li>
              <li class="flex">
                <span>通讯方式</span>
                <span>全网通SIM 4G+RJ45以太网口</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="item flex">
        <div class="label">环境参数</div>
        <div class="val flex">
          <div class="left">
            <ul>
              <li class="flex">
                <span>适用场所</span>
                <span>户外/户内</span>
              </li>
              <li class="flex">
                <span>工作温度（℃）</span>
                <span>-25-50(超过 45℃ 降频)</span>
              </li>
            </ul>
          </div>
          <div class="right">
            <ul>
              <li class="flex">
                <span>工作湿度</span>
                <span>≤ 95%，无冷凝</span>
              </li>
              <li class="flex">
                <span>工作海拔（m）</span>
                <span>≤ 2000</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="item flex">
        <div class="label">交流参数</div>
        <div class="val flex">
          <div class="left">
            <ul>
              <li class="flex">
                <span>额定交流功率（kVA）</span>
                <span>30</span>
              </li>
              <li class="flex">
                <span>交流过载方式（kVA）</span>
                <span>33kVA</span>
              </li>
              <li class="flex">
                <span>总电流谐波畸变率</span>
                <span>≤ 3%</span>
              </li>
              <li class="flex">
                <span>功率因素</span>
                <span>0.99/-1～1</span>
              </li>
            </ul>
          </div>
          <div class="right">
            <ul>
              <li class="flex">
                <span>接线方式</span>
                <span>三相四线+PE</span>
              </li>
              <li class="flex">
                <span>允许电网电压（Vac）</span>
                <span>380/400(-15%~15%)</span>
              </li>
              <li class="flex">
                <span>允许电网频率（Hz）</span>
                <span>50/60(-2.5~2.5)</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="item flex">
        <div class="label">安全标准</div>
        <div class="val flex">
          <div class="left max">
            <ul>
              <li class="flex">
                <span>输入保护</span>
                <span
                  >欠压保护，过压保护，过流保护，过温保护，漏电保护，防雷保护，短路保护</span
                >
              </li>
              <li class="flex">
                <span>输出保护</span>
                <span
                  >短路保护，过温保护，通信故障保护，漏电保护，过流保护</span
                >
              </li>
              <li class="flex">
                <span>紧急保护</span>
                <span
                  >设置紧急停止按钮，漏电保护功能，高精度输出绝缘监测功能</span
                >
              </li>
              <li class="flex">
                <span>特殊保护</span>
                <span>IP54保护等级，防盐雾，防潮，防毒，防紫外线</span>
              </li>
            </ul>
          </div>
          <div class="right"></div>
        </div>
      </div>
      <div class="item flex">
        <div class="label">认证标准</div>
        <div class="val flex">
          <div class="left max">
            <ul>
              <li class="flex">
                <span>电池</span>
                <span>GB/T36276、GB/T34131、GB/T 34120 GB/T 34133</span>
              </li>
              <li class="flex">
                <span>充电</span>
                <span style="text-align: left"
                  >GB/T 18487.1-2015、GB/T 18487.2-2015、GB/T 27930-2015、<br />
                  GB/T 20234.1-2015、NB/T 20234.3-2016</span
                >
              </li>
            </ul>
          </div>
          <div class="right"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        name: "",
        chuneng1: require("@/assets/product/img/chuneng3.jpg"),
        chuneng2: require("@/assets/product/img/chuneng2.png"),
        init: {
          prevone: {
            quantitative: "215kWh",
            size: "1300*1370*1985mm",
            type: "磷酸铁锂",
            standrand: "GB/T36276、GB/T34131、GB/T 34120、 GB/T 34133",
            power: "105kW",
            vol: "230/240V",
            frequency: "50/60Hz",
            sizes: "1300*1370*1985",
            weight: "≤ 25000",
            rate: "≥ 98.5 %",
            ip: "IP55",
            temperRang: "-30℃ ~ 55℃",
            safeMeter: "4000米，＞2000m 时降额使用",
            workType: "智能风冷",
            hour: "2",
          },
          prevtwo: {
            quantitative: "230kWh",
            size: "1210*1672*2184mm",
            type: "磷酸铁锂",
            standrand: "GB/T36276、GB/T34131、GB/T 34120、 GB/T 34133",
            power: "110kW",
            vol: "230/240V",
            frequency: "50/60Hz",
            sizes: "1210*1672*2184",
            weight: "≤ 29000",
            rate: "≥ 98.5 %",
            ip: "IP55",
            temperRang: "-30℃ ~ 55℃，（＞45℃降额）",
            safeMeter: "4000米，＞2000m 时降额使用",
            workType: "智能液冷",
            hour: "2",
          },
          prevthree: {
            quantitative: "400kWh",
            size: "1640*1665*2354mm",
            type: "磷酸铁锂",
            standrand: "GB/T36276、GB/T34131、GB/T 34120、 GB/T 34133",
            power: "200kW",
            vol: "690V",
            frequency: "50/60Hz",
            sizes: "1640*1665*2354",
            weight: "≤ 43000",
            rate: "≥ 98.5 %",
            ip: "IP55",
            temperRang: "-30℃ ~ 55℃",
            safeMeter: "4000米，＞2000m 时降额使用",
            workType: "智能液冷",
            hour: "2",
          },
          prevfour: {
            quantitative: "800kWh",
            size: "2720*1665*2354mm",
            type: "磷酸铁锂",
            standrand: "GB/T36276、GB/T34131、GB/T 34120、 GB/T 34133",
            power: "200kW",
            vol: "690V",
            frequency: "50/60Hz",
            sizes: "2720*1665*2354",
            weight: "≤ 80000",
            rate: "≥ 98.2 %",
            ip: "IP55",
            temperRang: "-30℃ ~ 55℃，（＞45℃降额）",
            safeMeter: "4000米，＞2000m 时降额使用",
            workType: "智能液冷",
            hour: "4",
          },
          prevstorage: {
            quantitative: "800kWh",
            size: "2720*1665*2354mm",
            type: "磷酸铁锂",
            standrand: "GB/T36276、GB/T34131、GB/T 34120、 GB/T 34133",
            power: "200kW",
            vol: "690V",
            frequency: "50/60Hz",
            sizes: "2720*1665*2354",
            weight: "≤ 80000",
            rate: "≥ 98.2 %",
            ip: "IP55",
            temperRang: "-30℃ ~ 55℃，（＞45℃降额）",
            safeMeter: "4000米，＞2000m 时降额使用",
            workType: "智能液冷",
            hour: "4",
          },
        },
      };
    },
    created() {
      this.name = this.$route.query?.type;
    },
  };
</script>
<style lang="less">
  .rule {
    width: 134.6rem;
    margin: 0 auto;

    .imgs {
      height: 57.1rem;
      justify-content: center;
      align-items: center;
      margin: 6rem auto;

      .img-box {
        height: 44.6rem;

        img {
          width: auto;
        }
      }
    }

    .data {
      .item {
        color: #ffffff;
        border-top: 0.1rem solid #595757;
        font-size: 3rem;
        padding: 5.2rem 0 5.2rem;

        .label {
          width: 16.2rem;
          font-size: 2.8rem;
          font-family: HarmonyBold;
          text-align: left;
          margin-right: 12.7rem;
        }

        .val {
          flex: 1;
          //   justify-content: space-between;
          padding: 0 4.2rem 0 0;

          .left {
            width: 37.2rem;

            &.max {
              width: fit-content;
            }
          }

          .right {
            // width: 30rem;
            margin-left: 16rem;
          }

          ul {
            display: flex;
            display: -webkit-flex;
            flex-direction: column;

            li {
              flex-direction: column;
              align-items: flex-start;
              font-size: 2.6rem;
              font-family: HarmonyLight;
              line-height: 3.6rem;
              margin-bottom: 9.2rem;

              span:first-of-type {
                font-size: 2.8rem;
                font-family: HarmonyBold;
                // margin-bottom: 2.4rem;
              }
              span {
                text-align: left;
                margin-bottom: 2.4rem;

                &:last-of-type {
                  margin-bottom: 0;
                }
              }

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>
