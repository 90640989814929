<template>
  <div>
    <template v-if="1">
      <Guige></Guige>
    </template>
    <template v-else>
      <div class="rule">
        <div class="imgs"></div>

        <div class="data">
          <div class="item flex">
            <div class="label">概要</div>
            <div class="val flex">
              <div class="left">
                <ul>
                  <li class="flex">
                    <span>容量</span>
                    <span>{{ init[name].quantitative }}</span>
                  </li>
                </ul>
              </div>
              <div class="right">
                <ul>
                  <li class="flex">
                    <span>尺寸：{{ init[name].size }}</span>
                    <span>电芯类型：{{ init[name].type }} </span>
                    <span>符合标准：{{ init[name].standrand }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="item flex">
            <div class="label">输出规格</div>
            <div class="val flex">
              <div class="left">
                <ul>
                  <li class="flex">
                    <span>额定输出功率：{{ init[name].power }} </span>
                    <span>额定电网电压：{{ init[name].vol }} </span>
                    <span>额定电网频率：{{ init[name].frequency }}</span>
                  </li>
                </ul>
              </div>
              <div class="right"></div>
            </div>
          </div>
          <div class="item flex">
            <div class="label">系统参数</div>
            <div class="val flex">
              <div class="left">
                <ul>
                  <li class="flex">
                    <span>柜体尺寸（mm）</span>
                    <span>{{ init[name].sizes }}</span>
                  </li>
                  <li class="flex">
                    <span>最大循环效率（%）</span>
                    <span>{{ init[name].rate }}</span>
                  </li>
                  <li class="flex">
                    <span>工作温度范围（℃）</span>
                    <span>{{ init[name].temperRang }}</span>
                  </li>
                  <li class="flex">
                    <span>机柜冷却方式</span>
                    <span>{{ init[name].workType }}</span>
                  </li>
                </ul>
              </div>
              <div class="right">
                <ul>
                  <li class="flex">
                    <span>重量（Kg）</span>
                    <span>{{ init[name].weight }}</span>
                  </li>
                  <li class="flex">
                    <span>防护等级</span>
                    <span>{{ init[name].ip }} </span>
                  </li>
                  <li class="flex">
                    <span>最高工作海拔（米）</span>
                    <span>{{ init[name].safeMeter }}</span>
                  </li>
                  <li class="flex">
                    <span>单次充/放电时长（小时）</span>
                    <span>{{ init[name].hour }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import Guige from "./chuneng.vue";
  export default {
    components: {
      Guige,
    },
    data() {
      return {
        name: "",
        init: {
          prevone: {
            quantitative: "215kWh",
            size: "1300*1370*1985mm",
            type: "磷酸铁锂",
            standrand: "GB/T36276、GB/T34131、GB/T 34120、 GB/T 34133",
            power: "105kW",
            vol: "230/240V",
            frequency: "50/60Hz",
            sizes: "1300*1370*1985",
            weight: "≤ 25000",
            rate: "≥ 98.5 %",
            ip: "IP55",
            temperRang: "-30℃ ~ 55℃",
            safeMeter: "4000米，＞2000m 时降额使用",
            workType: "智能风冷",
            hour: "2",
          },
          prevtwo: {
            quantitative: "230kWh",
            size: "1210*1672*2184mm",
            type: "磷酸铁锂",
            standrand: "GB/T36276、GB/T34131、GB/T 34120、 GB/T 34133",
            power: "110kW",
            vol: "230/240V",
            frequency: "50/60Hz",
            sizes: "1210*1672*2184",
            weight: "≤ 29000",
            rate: "≥ 98.5 %",
            ip: "IP55",
            temperRang: "-30℃ ~ 55℃，（＞45℃降额）",
            safeMeter: "4000米，＞2000m 时降额使用",
            workType: "智能液冷",
            hour: "2",
          },
          prevthree: {
            quantitative: "400kWh",
            size: "1640*1665*2354mm",
            type: "磷酸铁锂",
            standrand: "GB/T36276、GB/T34131、GB/T 34120、 GB/T 34133",
            power: "200kW",
            vol: "690V",
            frequency: "50/60Hz",
            sizes: "1640*1665*2354",
            weight: "≤ 43000",
            rate: "≥ 98.5 %",
            ip: "IP55",
            temperRang: "-30℃ ~ 55℃",
            safeMeter: "4000米，＞2000m 时降额使用",
            workType: "智能液冷",
            hour: "2",
          },
          prevfour: {
            quantitative: "800kWh",
            size: "2720*1665*2354mm",
            type: "磷酸铁锂",
            standrand: "GB/T36276、GB/T34131、GB/T 34120、 GB/T 34133",
            power: "200kW",
            vol: "690V",
            frequency: "50/60Hz",
            sizes: "2720*1665*2354",
            weight: "≤ 80000",
            rate: "≥ 98.2 %",
            ip: "IP55",
            temperRang: "-30℃ ~ 55℃，（＞45℃降额）",
            safeMeter: "4000米，＞2000m 时降额使用",
            workType: "智能液冷",
            hour: "4",
          },
          prevstorage: {
            quantitative: "800kWh",
            size: "2720*1665*2354mm",
            type: "磷酸铁锂",
            standrand: "GB/T36276、GB/T34131、GB/T 34120、 GB/T 34133",
            power: "200kW",
            vol: "690V",
            frequency: "50/60Hz",
            sizes: "2720*1665*2354",
            weight: "≤ 80000",
            rate: "≥ 98.2 %",
            ip: "IP55",
            temperRang: "-30℃ ~ 55℃，（＞45℃降额）",
            safeMeter: "4000米，＞2000m 时降额使用",
            workType: "智能液冷",
            hour: "4",
          },
        },
      };
    },
    created() {
      this.name = this.$route.query?.type;
      console.log("create", this.$route);
    },
  };
</script>
<style lang="less" scoped>
  .rule {
    width: 134.6rem;
    margin: 0 auto;

    .imgs {
      height: 60.8rem;
      margin: 6rem auto;
    }

    .data {
      .item {
        color: #ffffff;
        border-top: 0.1rem solid #595757;
        font-size: 2.6rem;
        font-weight: bold;
        padding: 5.2rem 0 5.2rem;

        .label {
          width: 16.2rem;
          font-size: 2.8rem;
          text-align: left;
          margin-right: 12.7rem;
        }

        .val {
          flex: 1;
          justify-content: space-between;
          padding: 2.1rem 4.2rem 0 0;

          ul {
            display: flex;
            display: -webkit-flex;
            flex-direction: column;

            li {
              flex-direction: column;
              align-items: flex-start;
              font-size: 1.8rem;
              line-height: 3.6rem;
              margin-bottom: 9.2rem;

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>
